var nav = function($id) {
  this.class = 'is-open';

  this.init = function() {
    this.el = document.getElementById($id);

    if(!this.el) {
      return;
    }

    this.open = this.el.querySelector('[data-open]');
    this.close = this.el.querySelector('[data-close]');
    this.list = this.el.querySelector('[data-list]');
    
    this.open.onclick = function() {
      this.initToggle(this.list);
      return false;
    }.bind(this);
    
    this.close.onclick = function() {
      this.initToggle(this.list);
      return false;
    }.bind(this);
  }

  this.initToggle = function($el) {
    if($el.classList.contains(this.class)) {
      $el.classList.remove(this.class);
      document.querySelector('body').style.overflow = null;
    } else {
      $el.classList.add(this.class);
      document.querySelector('body').style.overflow = 'hidden';
    }
  }
};

