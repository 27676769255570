var tabs = function($id) {
  this.init = function() {
    this.class = "is-active";
    this.el = document.getElementById($id);

    if(!this.el) {
      return;
    }

    this.items = this.el.querySelectorAll('a');
    
    
    
    /*this.items.forEach(function($item) {
      this.initTab($item);
    }.bind(this));*/
    
    
	var i;
    for(i=0; i < this.items.length; i++) {
	    this.initTab(this.items[i]);
    };

    if(window.location.hash) {
      var $activeTab = this.getActiveTab(this.items);
      var $hash = window.location.hash.replace('#','');
      var $el = document.querySelector('[href*='+$hash+']');
      this.setActiveTab($activeTab, $el);
    }
  }

  this.initTab = function($el) {
    $el.onclick = function() {
      var $activeTab = this.getActiveTab(this.items);
      
      if($el != $activeTab) {
        this.setActiveTab($activeTab, $el);
      }

      return false;
    }.bind(this)
  }

  this.getActiveTab = function($items) {
    var $activeTab;

    /*$items.forEach(function($item) {
      if($item.classList.contains(this.class)) {
        $activeTab = $item;
      }
    }.bind(this));*/
    
    
    
	var i;
    for(i=0; i < this.items.length; i++) {
      if(this.items[i].classList.contains(this.class)) {
        $activeTab = this.items[i];
      }
    };

    return $activeTab;
  }

  this.setActiveTab = function($oldItem, $newItem) {
    $oldItem.classList.remove(this.class);
    $newItem.classList.add(this.class);
    var $href = $newItem.getAttribute('href');
    history.pushState(null, null, $href);

    var $oldContent = document.getElementById($oldItem.getAttribute('href').replace('#', '')),
        $newContent = document.getElementById($newItem.getAttribute('href').replace('#', ''));
    
    $oldContent.classList.remove(this.class);
    $newContent.classList.add(this.class);
  }
}