var googleMap = function($id, $data, $templatelocation) {
  this.templatelocation = $templatelocation;

  this.init = function() {
    this.el = document.getElementById($id);

    if(!this.el) {
      return;
    }

    this.data = $data;

    this.initMap(this.el);
    this.initMarkers(this.data);
    this.initInfowindows(this.data);
  }

  this.initMap = function($el) {
    this.map = new google.maps.Map($el, {
      center: {
        lat: 50.4956976, 
        lng: 3.3452619
      },
      zoom: 8
    });
  }

  this.initMarkers = function($locations) {
    this.bounds = new google.maps.LatLngBounds();
    this.markers = [];
    this.icon = {
      url: this.templatelocation+"dist/img/marker.png",
      scaledSize: new google.maps.Size(32, 48),
    }

    $locations.forEach(function($data, $i) {
      var $latLng = new google.maps.LatLng($data.lat, $data.lng);
      
      this.bounds.extend($latLng);

      this.markers[$i] = new google.maps.Marker({
        position: $latLng, 
        title: $data.city,
        map: this.map,
        icon: this.icon
      });
    }.bind(this));

    this.map.fitBounds(this.bounds);
  }

  this.initInfowindows = function($locations) {
    this.infowindows = [];

    $locations.forEach(function($data, $i){
      this.infowindows[$i] = new google.maps.InfoWindow({
        content: `
        <div class="infowindow">
          <h6 class="infowindow-title">` + $data.city + `</h6>
          <p>
            ` + $data.address + `<br>
            ` + $data.zip + ` ` + $data.city + `
          </p>
          <p class="infowindow-url">
            <a href="` + $data.call_to_action.url + `" title="` + $data.call_to_action.label + `">` + $data.call_to_action.label + `</a>
          </p>
        </div>
        `
      });

      this.markers[$i].addListener('click', function() {
        this.infowindows[$i].open(this.map, this.markers[$i]);
      }.bind(this));
    }.bind(this));
  }
}