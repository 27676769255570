var timeslot = function($timeslot, $timeslotList, $data, $templatelocation) {
  this.selectedSlots = [];
  this.templatelocation = $templatelocation;

  this.init = function() {
    // Check if moment.js is loaded
    try {
      moment();
    }

    catch(e) {
      console.error('Timeslot requires moment.js');
    }
    
    // Set locale
    moment.locale('nl');

    // Set element & data
    this.timeslot = document.getElementById($timeslot);
    this.timeslotList = document.getElementById($timeslotList);
    this.data = $data;
    this.currentWeek = 0;

    // Set minimum & maximum hour
    this.setMinMaxHour();

    // Render timeslot
    this.renderTimeslot();
  }

  this.renderTimeslot = function() {
    // Render pager
    this.renderPager();

    // Render days
    this.renderDays();

    // Render hours
    this.renderHours();

    // Render slots
    this.renderSlots();

    // Render form
    this.renderForm();
  }

  this.renderDays = function() {
    // Create days container
    var $timeslotPickerDays = document.createElement('div');
        $timeslotPickerDays.className = 'timeslot-picker-days';

    // For each day in the current week
    for(var $i = 0; $i < this.data[this.currentWeek].length; $i++) {
      // Render day node
      var $node = this.renderDay(this.data[this.currentWeek][$i].date);
          
      // Append day node to container
      $timeslotPickerDays.appendChild($node);
    }

    // Append days to timeslot
    this.timeslot.appendChild($timeslotPickerDays);
  }

  this.renderDay = function($day) {
    // Create date obj
    var $day = moment($day);

    // Create formatted weekday and date
    var $weekday = $day.format('dd'),
        $day = $day.format('D') + ' ' + $day.format('MMMM').substring(0, 3);

    // Create node
    var $node = document.createElement('div');
        $node.className = 'timeslot-picker-day';
        $node.innerHTML = `
          <span>` + $weekday + `</span>
          <br>
          ` + $day + `
        `;
        
    // Return node
    return $node;
  }

  this.renderHours = function() {
    // Create hours container
    var $timeslotPickerHours = document.createElement('div');
        $timeslotPickerHours.className = 'timeslot-picker-hours';

    // For each hour between min and max
    for(var $i = this.minHour; $i <= this.maxHour; $i++) {
      var $hour = this.renderHour($i);

      // Append hour node to container
      $timeslotPickerHours.appendChild($hour);
    }

    // Append hours to timeslot
    this.timeslot.appendChild($timeslotPickerHours);
  }

  this.renderHour = function($hour) {
    // Create node
    var $node = document.createElement('div');
        $node.className = 'timeslot-picker-hour';
        $node.innerHTML = `
          <span class="timeslot-picker-hour-label">
            ` + $hour + `:00
          </span>
        `;

    // Return node
    return $node;
  }

  this.renderSlots = function() {
    var $timeslotPickerSlotsWeek = this.renderSlotsWeek();

    // Append slots to timeslot
    this.timeslot.appendChild($timeslotPickerSlotsWeek);
  }

  this.renderSlotsWeek = function() {
    // Create week
    var $timeslotPickerSlotsWeek = document.createElement('div');
        $timeslotPickerSlotsWeek.className = 'timeslot-picker-slots-week';

    for(var $i = 0; $i < this.data[this.currentWeek].length; $i++) {
      var $timeslotPickerSlotsDay = this.renderSlotsDay(this.data[this.currentWeek][$i]);

      // Append day to week
      $timeslotPickerSlotsWeek.appendChild($timeslotPickerSlotsDay);
    }

    return $timeslotPickerSlotsWeek;
  }

  this.renderSlotsDay = function($day) {
    // Create day
    var $timeslotPickerSlotsDay = document.createElement('div');
        $timeslotPickerSlotsDay.className = 'timeslot-picker-slots-day';

    // For each hour between min and max
    for(var $i = this.minHour; $i <= this.maxHour; $i++) {
      var $timeslotPickerSlotsHour = this.renderSlotsHour();

      // Append hour node to container
      $timeslotPickerSlotsDay.appendChild($timeslotPickerSlotsHour);
    }
    
    // For each slot
    for(var $i = 0; $i < $day.slots.length; $i++) {
      // Render slot
      var $timeslotPickerSlotsSlot = this.renderSlotsSlot($day, $i);

      // Append slot node to container
      $timeslotPickerSlotsDay.appendChild($timeslotPickerSlotsSlot);
    }

    // Return day
    return $timeslotPickerSlotsDay;
  }

  this.renderSlotsHour = function() {
    // Create hour
    var $timeslotPickerSlotsHour = document.createElement('div');
        $timeslotPickerSlotsHour.className = 'timeslot-picker-slots-hour';

    // Return hour
    return $timeslotPickerSlotsHour;
  }

  this.renderSlotsSlot = function($day, $i) {
    var $qryDuplicate = this.selectedSlots.filter(function($obj) {
      return $obj.id == $day.slots[$i].id;
    });

    // Create slot
    var $timeslotPickerSlotsSlot = document.createElement('div');
        $timeslotPickerSlotsSlot.className = ($qryDuplicate.length > 0 ? 'timeslot-picker-slots-slot is-selected' : 'timeslot-picker-slots-slot');
        $timeslotPickerSlotsSlot.dataset.start = (($day.slots[$i].start - this.minHour) + .5)*2;
        $timeslotPickerSlotsSlot.dataset.span = ($day.slots[$i].end - $day.slots[$i].start) * 2;
        $timeslotPickerSlotsSlot.dataset.timeslotstring = $day.slots[$i].timeslotstring;
        $timeslotPickerSlotsSlot.dataset.price = $day.slots[$i].price;
        $timeslotPickerSlotsSlot.innerHTML = `
          <div class="timeslot-picker-slots-slot-label">
            ` + moment($day.date).format('dddd, D MMMM Y') + `
            <br>
            <span>` + $day.slots[$i].start + `:00 - ` + $day.slots[$i].end + `:00</span>
          </div>
        `;
        $timeslotPickerSlotsSlot.onclick = function() {
          // Update selection
          this.updateSelection($day, $i);

          // Prevent default events
          return false;
        }.bind(this);

    // Return slot
    return $timeslotPickerSlotsSlot;
  }

  this.updateSelection = function($day, $i) {
    // Check for duplicate
    var $qryDuplicate = this.selectedSlots.filter(function($obj) {
      return $obj.id == $day.slots[$i].id;
    })

    if($qryDuplicate.length > 0) {
      // Remove from selection
      this.removeFromSelection($qryDuplicate[0].id);
    } else {
      var $qryMaxPerDay = this.selectedSlots.filter(function($obj) {
        return $obj.date == $day.date;
      });

      if($qryMaxPerDay.length > 0) {
        var $swap = confirm('U mag maar 1 les per dag selecteren, wilt u uw blok wijzigen?');
        
        if(!$swap) {
          return;
        } else {
          for(var $j = 0; $j < this.selectedSlots.length; $j++) {
            if(this.selectedSlots[$j].date == $day.date) {
              this.removeFromSelection(this.selectedSlots[$j].id);
            }
          }

          this.addToSelection($day, $i);
        }
      } else {
        // Add to selection
        this.addToSelection($day, $i);
      }
    }

    // Clear current timeslot
    this.timeslot.innerHTML = '';

    // Re-render timeslot
    this.renderTimeslot();
  }

  this.addToSelection = function($day, $i) {
    // Create object
    var $currentDay = {
      date: $day.date,
      start: $day.slots[$i].start,
      end: $day.slots[$i].end,
      timeslotstring: $day.slots[$i].timeslotstring,
      price: $day.slots[$i].price,
      id: $day.slots[$i].id
    }

    var btn = document.getElementById('subscribebutton');
    btn.style.display = 'block';

    // Push into array
    this.selectedSlots.push($currentDay);
  }

  this.removeFromSelection = function($id) {
    // Check if id is found in selection
    for(var $i = 0; $i < this.selectedSlots.length; $i++) {
      if(this.selectedSlots[$i].id == $id) {
        // Remove slot from array
        this.selectedSlots.splice($i, 1);
      }
    }
    if(!this.selectedSlots.length){
      var btn = document.getElementById('subscribebutton');
      btn.style.display = 'none';
    }
  }

  this.renderForm = function() {
    // Create timeslot list
    var $timeslotList = document.createElement('ul');
        $timeslotList.className = 'timeslot-list';

    if(!this.selectedSlots.length) {
      var $timeslotListItem = document.createElement('li');
          $timeslotListItem.className = 'timeslot-list-item';
          $timeslotListItem.innerHTML = 'U hebt nog geen data geselecteerd.';

      $timeslotList.appendChild($timeslotListItem);
    }

    // For each selected slot
    for(var $i = 0; $i < this.selectedSlots.length; $i++) {
      var $timeslotListItem = document.createElement('li');
          $timeslotListItem.className = 'timeslot-list-item';

      // Get day & timespan
      var $day = moment(this.selectedSlots[$i].date).format('dddd, D MMMM Y');
      var $timespan = this.selectedSlots[$i].start + ':00 - ' + (this.selectedSlots[$i].end == 24 ? '00' : this.selectedSlots[$i].end) + ':00';
          
      // Render button
      var $button = this.renderButtonRemove(this.selectedSlots[$i].id);

      // Append button
      $timeslotListItem.appendChild($button);
          
      // Create label
      var $label = document.createElement('label');
          $label.className = 'timeslot-list-item-input';
          $label.innerHTML = `
            <input type="checkbox" name="data[]" value="` + this.selectedSlots[$i].id + `" checked>
            ` + $day + `
            <br>
            <span>` + this.selectedSlots[$i].timeslotstring + ` &nbsp;&nbsp;-&nbsp;&nbsp; &euro; ` + this.selectedSlots[$i].price + `</span>
          `;

      // Append label
      $timeslotListItem.appendChild($label);

      // Append to timeslot list
      $timeslotList.appendChild($timeslotListItem);
    }

    // Clear container
    this.timeslotList.innerHTML = '';

    // Append to container
    this.timeslotList.appendChild($timeslotList);
  }

  this.renderButtonRemove = function($id) {
    // Create button
    var $button = document.createElement('button');
        $button.className = 'timeslot-list-item-remove';
        $button.innerHTML = `
          <svg class="icon-md icon-red">
            <use xlink:href="`+this.templatelocation+`dist/icons/icons.svg#remove"></use>
          </svg>
        `;
        $button.onclick = function() {
          // Remove from selected slots
          this.removeFromSelection($id);

          // Clear current timeslot
          this.timeslot.innerHTML = '';

          // Re-render timeslot
          this.renderTimeslot();

          // Prevent default events
          return false;
      }.bind(this);

    return $button;
  }

  this.renderPager = function() {
    // If prev or next weeks
    if(this.currentWeek > 0 || this.currentWeek < (this.data.length - 1)) {
      // Create actions container
      var $timeslotPickerActions = document.createElement('div');
          $timeslotPickerActions.className = 'timeslot-picker-pager';

      // If prev weeks
      if(this.currentWeek > 0) {
        var $node = this.renderPrev();

        // Append prev node to actions
        $timeslotPickerActions.appendChild($node);
      } else {
        var $node = document.createElement('div');
            $node.className = 'timeslot-picker-prev';

        $timeslotPickerActions.appendChild($node);
      }

      // Week n°
      var $weekNumber = moment(this.data[this.currentWeek][0].date).format('W');
      console.log($weekNumber);

      // Create title
      var $title = document.createElement('span');
          $title.className = 'timeslot-picker-title';
          $title.innerHTML = 'Week ' + $weekNumber;

      $timeslotPickerActions.appendChild($title);

      // If next weeks
      if(this.currentWeek < (this.data.length - 1)) {
        var $node = this.renderNext();

        // Append next node to actions
        $timeslotPickerActions.appendChild($node);
      } else {
        var $node = document.createElement('div');
            $node.className = 'timeslot-picker-next';

        $timeslotPickerActions.appendChild($node);
      }

      // Append actions
      this.timeslot.appendChild($timeslotPickerActions);
    }
  }

  this.renderPrev = function() {
    // Create prev
    var $prev = document.createElement('button');
        $prev.className = 'timeslot-picker-prev';
        $prev.innerHTML = `
        <svg class="icon-xs icon-white">
          <use xlink:href="`+this.templatelocation+`dist/icons/icons.svg#prev"></use>
        </svg>
        `;
        $prev.onclick = function() {
          // Minus current week
          this.currentWeek--;

          // Clear current timeslot
          this.timeslot.innerHTML = '';

          // Re-render timeslot
          this.renderTimeslot();

          // Prevent default events
          return false;
        }.bind(this);

    // Return prev
    return $prev;
  }

  this.renderNext = function() {
    // Create node
    var $next = document.createElement('button');
        $next.className = 'timeslot-picker-next';
        $next.innerHTML = `
        <svg class="icon-xs icon-white">
          <use xlink:href="`+this.templatelocation+`dist/icons/icons.svg#next"></use>
        </svg>
        `;
        $next.onclick = function() {
          // Add current week
          this.currentWeek++;

          // Clear current timeslot
          this.timeslot.innerHTML = '';

          // Re-render timeslot
          this.renderTimeslot();

          // Prevent default events
          return false;
        }.bind(this);

    // Return node
    return $next;
  }

  this.setMinMaxHour = function() {
    var $min = null,
        $max = null;

    for(var $i = 0; $i < this.data.length; $i++) {
      var $week = this.data[$i];

      for(var $j = 0; $j < $week.length; $j++) {
        var $day = $week[$j],
            $slots = $day.slots;
        
        for(var $k = 0; $k < $slots.length; $k++) {
          // Set min
          if($min == null) {
            $min = $slots[$k].start;
          }

          if($slots[$k].start < $min) {
            $min = $slots[$k].start;
          }

          // Set max
          if($max == null) {
            $max = $slots[$k].end;
          }

          if($slots[$k].end > $max) {
            $max = $slots[$k].end;
          }
        }
      }
    }

    this.minHour = ($min > 10 ? 10 : (Number.isInteger($min) ? $min : Math.floor($min)));
    this.maxHour = ($max < 18 ? 18 : (Number.isInteger($max) ? $max : Math.ceil($max)));
  }
}

Number.isInteger = Number.isInteger || function(value) {
    return typeof value === "number" && 
           isFinite(value) && 
           Math.floor(value) === value;
};