var totop = function($id) {
  this.init = function() {
    // Get element by id
    this.button = document.getElementById($id);

    // On click
    this.button.onclick = function() {
      this.scrollToTop();
    }.bind(this);

    // On scroll
    window.onscroll = function() {
      this.toggleButton(this.button);
    }.bind(this);
  }

  this.scrollToTop = function() {
    $('html').animate({
      scrollTop: 0
    }, 1000);
  }

  this.toggleButton = function($button) {
    var offsetY = window.pageYOffset;
    var heightHeader = document.querySelector('.header').clientHeight;

    if(offsetY > heightHeader) {
      $button.classList.add('is-visible');
    } else {
      $button.classList.remove('is-visible');
    }
  }
}