var carousel = function($id) {
  this.init = function() {
    this.el = document.getElementById($id);

    if(!this.el) {
      return;
    }

    this.slides = this.el.querySelector('[data-carousel]');

    tns({
      container: this.slides,
      controls: false,
      autoplay: true,
      speed: 1000
    });
  }
}